import $ from 'jquery'
import 'slick-carousel'
import AOS from 'aos';
import './mini-cart';

import 'aos/dist/aos.css';

const coreHeader =  $("#core-header");

/***************************************
 * AOS Effect
 ***************************************/
AOS.init({
  duration: 750, easing: 'ease', offset: -100, once: true,
});


/***************************************
 * Mega menu
 ***************************************/
const megaMenuItem = $('.mega-menu-hover');
const megaMenu = $('.mega-menu')
const megaMenuBackdrop = $('.mega-menu--backdrop');

megaMenuItem.add(megaMenu).hover(function () {
  megaMenu.addClass('show');
  megaMenuBackdrop.addClass('show');
  coreHeader.addClass('no-box-shadow');
}, function () {
  megaMenu.removeClass('show');
  megaMenuBackdrop.removeClass('show');
  coreHeader.removeClass('no-box-shadow')
})

const megaMenuMainItems = $('.main-items li');
const megaMenuMenus = $('.mega-menu .menu')

megaMenuMainItems.hover(function () {
  let menu = $($(this).data('hover'));

  // Toggle menu item classes
  megaMenuMainItems.not($(this)).removeClass('active');
  $(this).addClass('active');

  // Toggle menu classes
  megaMenuMenus.not(menu).removeClass('show');
  menu.addClass('show');
})


$('.header-slider').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
});

$('#new-products-slider, #most-sold-products-slider, #logo-slider').slick({
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
  prevArrow: '<div class="prev"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256"><path d="M165.66,202.34a8,8,0,0,1-11.32,11.32l-80-80a8,8,0,0,1,0-11.32l80-80a8,8,0,0,1,11.32,11.32L91.31,128Z"></path></svg></div>',
  nextArrow: '<div class="next"><svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000000" viewBox="0 0 256 256">\n' +
    '    <path\n' +
    '      d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>\n' +
    '  </svg></div>',
});

/***************************************
 * Scroll function
 ***************************************/

myNavOnScroll();

$(window).on('scroll', myNavOnScroll);

function myNavOnScroll() {
  let scrollPosition = Math.round(window.scrollY);

  if (scrollPosition > 2) {
    coreHeader.addClass("sticky");
    megaMenu.addClass("sticky");
  } else {
    coreHeader.removeClass("sticky");
    megaMenu.removeClass("sticky");
  }

}
